<template>
    <el-main>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <el-form-item label="排序：" prop="sort">
                <el-input v-model="ruleForm.sort"></el-input>
            </el-form-item>
            <el-form-item label="合作类型：" prop="project_type">
                <el-select v-model="ruleForm.project_type" multiple placeholder="请选择">
                    <el-option v-for="item in cateInfo.list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合作标题：" prop="title">
                <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="列表图：" prop="image">
                <ReadyUploadSource @getSource="(val) => ruleForm.image = val.path" @removeThis="ruleForm.image = ''"
                    :path="ruleForm.image"></ReadyUploadSource>
                <div class="tips">建议图片比例1:1</div>
            </el-form-item>
            <el-form-item label="需求类型：" prop="need_type">
                <el-select v-model="ruleForm.need_type" multiple placeholder="请选择">
                    <el-option v-for="item in demandList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="预计孵化周期：" prop="time">
                <el-input v-model="ruleForm.time"></el-input><span class="ml20">天</span>
            </el-form-item>
            <el-form-item label="合作启动资金：" prop="start_price">
                <el-input v-model="ruleForm.start_price"></el-input>
            </el-form-item>
            <el-form-item label="市场预期收益(元)：" prop="price">
                <el-input v-model="ruleForm.price"></el-input>
            </el-form-item>
            <el-form-item label="项目实施计划：" prop="plan">
                <el-input type="textarea" :rows="4" placeholder="请填写详情项目实施计划" v-model="ruleForm.plan">
                </el-input>
            </el-form-item>
            <el-form-item label="所属区域：" prop="area_arr">
                <el-cascader v-model="ruleForm.area_arr" :options="allAreaList"
                    :props="{ expandTrigger: 'hover', children: '_child', label: 'name', value: 'id' }">
                </el-cascader>
            </el-form-item>
            <el-form-item label="到期日期：" prop="promot">
                <el-select v-model="ruleForm.promot" placeholder="请选择">
                    <el-option v-for="item in endDate" :key="item.status" :label="item.name"
                        :value="item.status"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <Preservation @preservation="Preservation"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import _ from 'lodash'
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components: {
        Preservation,
        ReadyUploadSource
    },
    data () {
        return {
            edit_id: null,
            ruleForm: {
                sort: 99,
                project_type: '',
                title: '',
                image: '',
                need_type: null,
                time: '',
                start_price: '',
                price: '',
                plan: '',
                area_arr: [],
                promot: 'three',
            },
            rules: {
                sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
                project_type: [{ required: true, message: '请选择合作类型', trigger: 'blur' }],
                title: [{ required: true, message: '请输入合作标题', trigger: 'blur' }],
                image: [{ required: true, message: '请上传列表图', trigger: 'blur' }],
                need_type: [{ required: true, message: '请选择需求类型', trigger: 'blur' }],
                time: [{ required: true, message: '请输入预计孵化周期', trigger: 'blur' }],
                start_price: [{ required: true, message: '请输入合作启动资金', trigger: 'blur' }],
                price: [{ required: true, message: '请输入市场预期收益', trigger: 'blur' }],
                plan: [{ required: true, message: '请输入项目实施计划', trigger: 'blur' }],
                area_arr: [{ required: true, message: '请选择所属区域', trigger: 'blur' }],
                promot: [{ required: true, message: '请选择到期日期', trigger: 'blur' }],
            },
            // 项目类型列表
            cateInfo: {
                list: []
            },
            // 需求类型集合
            demandList: ['项目融资', '平台推广', '线下活动', '人力资源', '项目外包', '技术合作'],
            // 地区列表
            allAreaList: [],
            // 结束区间列表
            endDate: [
                { status: 'three', name: '三个月' },
                { status: 'six', name: '六个月' },
                { status: 'year', name: '十二个月' },
            ]
        }
    },
    created () {
        this.getCateList()
        this.getArea()
        if (this.$route.query.edit_id) {
            this.edit_id = this.$route.query.edit_id
            this.getInfo()
        }
    },
    methods: {
        // 编辑时查看信息
        getInfo () {
            this.$axios.post(this.$api.gateway.cooperation.info, {
                id: this.edit_id
            }).then(res => {
                if (res.code == 0) {
                    for (const key in this.ruleForm) {
                        if (key == 'area_arr') {
                            this.ruleForm[key] = [res.result.province_id, res.result.city_id]
                        } else if (key == 'need_type') {
                            this.ruleForm[key] = res.result[key].split(',')
                        } else if (key == 'project_type') {
                            this.ruleForm[key] = res.result[key].split(',').map(Number)
                        } else {
                            this.ruleForm[key] = res.result[key]
                        }
                    }
                }
            })
        },
        // 获取省市区信息
        getArea () {
            this.$axios
                .get(this.$api.shopDiy.areaList, {
                    tree: 1,
                    level: 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.allAreaList = res.result.list;
                    }
                });
        },
        getCateList () {
            this.$axios
                .post(this.$api.gateway.service.threeLevelList, {
                    name,
                    type: 7,
                })
                .then(res => {
                    if (res.code === 0) {
                        this.cateInfo.list = res.result || [];
                    }
                });
        },
        Preservation () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let obj = _.cloneDeep(this.ruleForm)
                    obj.province_id = this.ruleForm.area_arr[0]
                    obj.city_id = this.ruleForm.area_arr[1]
                    delete obj.area_arr
                    if (this.ruleForm.need_type?.length) obj.need_type = this.ruleForm.need_type.join(',')
                    if (this.ruleForm.project_type?.length) obj.project_type = this.ruleForm.project_type.join(',')
                    if (this.edit_id) obj.id = this.edit_id
                    let path = this.edit_id ? this.$api.gateway.cooperation.edit : this.$api.gateway.cooperation.add
                    this.$axios.post(path, obj).then(res => {
                        if (res.code == 0) {
                            this.$message.success('保存成功')
                            this.$router.push('/extension/cooperation/list')
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
    min-height: 100%;
    padding-bottom: 80px;

    .tips {
        line-height: 24px;
        color: #ccc;
    }

    .el-form {

        .el-input,
        .el-textarea {
            width: 600px;
        }

        .ml20 {
            margin-left: 20px;
        }
    }
}
</style>